import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Fade } from 'react-reveal'

import Layout from '../../layout'

import * as styles from './partners.module.scss'

const Partner = ({ pageContext: { name }, location }) => {
  const { partnersJson: { partners } } = useStaticQuery(graphql`{
    partnersJson(fields: {slug: {eq: "/partners/partners/"}}) {
      partners {
        name
        logo
        phoneNumber
        text
        website
        location
        images
        doctors
        boldText
      }
    }
  }`)
  const [active, setActive] = useState(0)
  const [partner] = useState(partners.find(partner => partner.name === name))

  const handleNext = () => {
    setActive(active === partner.images.length - 1 ? 0 : active + 1)
  }

  const handlePrevious = () => {
    setActive(active === 0 ? partner.images.length - 1 : active - 1)
  }

  return (
    <Layout title={partner.name} description='description' metaTags={[]} image={null} location={location} slug='slug' components={[]}>
      <section className={styles.section}>
        <Fade down cascade>
          <div className={styles.info}>
            <h1 className={styles.name}>{partner.name}</h1>
            <h2 className={styles.location}>{partner.location}</h2>
            <div className={styles.details}>
              {partner.doctors && partner.doctors.length ? partner.doctors.map((doctor, i) => <p key={i} className={styles.detail}>{doctor}</p>) : null}
              {partner.website ? (
                <p className={styles.detail}>
                  <a href={partner.website} target='_blank' rel='noopener noreferrer'>{partner.website.replace('https://', '').replace('http://', '').replace('www.', '')}</a>
                </p>
              ) : null}
              {partner.phoneNumber ? (
                <p className={styles.detail}>
                  <a href={`tel:+1${partner.phoneNumber}`} target='_blank' rel='noopener noreferrer'>({partner.phoneNumber.substr(0, 3)}) {partner.phoneNumber.substr(3, 3)}-{partner.phoneNumber.substr(6, 4)}</a>
                </p>
              ) : null}
            </div>
          </div>
          <div className={styles.images}>
            <div className={styles.imageContainer + `${partner.images && partner.images.length ? '' : ` ${styles.logoFill}`}`}>
              <div className={styles.image + `${partner.images && partner.images.length ? ' background-cover-center' : ` background-contain-center`}`} style={{ backgroundImage: `url(${partner.images[active] || partner.logo})` }} />
              </div>
            {partner.images.length > 1 && (
              <div className={styles.arrows}>
                <div className={styles.left} onClick={handlePrevious} />
                <div className={styles.right} onClick={handleNext} />
              </div>
            )}
          </div>
          <div className={styles.text}>
            <div className={styles.boldText}>
              {partner.boldText.split('\n').map((paragraph, i) => <p key={i} className={styles.paragraph}>{paragraph}</p>)}
            </div>
            <div className={styles.regularText}>
              {partner.text.split('\n').map((paragraph, i) => <p key={i} className={styles.paragraph}>{paragraph}</p>)}
            </div>
          </div>
        </Fade>
      </section>
    </Layout >
  )
}

export default Partner
