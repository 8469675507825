// extracted by mini-css-extract-plugin
export var section = "partners-module--section--v6HbB";
export var info = "partners-module--info--VAcgS";
export var name = "partners-module--name--1PXW4";
export var location = "partners-module--location--2P_d2";
export var details = "partners-module--details--2dG3h";
export var detail = "partners-module--detail--3PriW";
export var images = "partners-module--images--1F9BV";
export var imageContainer = "partners-module--image-container--3d9r_";
export var logoFill = "partners-module--logo-fill--3cmdx";
export var image = "partners-module--image--1zDG8";
export var arrows = "partners-module--arrows--12Kw8";
export var left = "partners-module--left--rxN1O";
export var right = "partners-module--right--2XtPE";
export var text = "partners-module--text--zdLD-";
export var boldText = "partners-module--bold-text--Hv0tB";
export var paragraph = "partners-module--paragraph--1fHUm";
export var regularText = "partners-module--regular-text--6Q2-z";